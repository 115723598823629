<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="white pa-5 rounded-0">
        <v-simple-table
          fixed-header
          height="500"
        >
          <template>
            <thead>
              <tr>
                <th>Новый №</th>
                <th
                  v-for="header in headers"
                  :key="header.value"
                  scope="row"
                  class="text-left"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in items"
                :key="idx"
              >
                <td width="100">
                  <v-text-field
                    dense
                    background-color="grey lighten-2"
                    solo
                    hide-details="auto"
                    flat
                    :value="item.new_sort_index"
                    @change="(newValue) => changeSorting(newValue, idx, item)"
                  />
                </td>
                <td
                  v-for="header in headers"
                  :key="`${idx}-${header.value}`"
                >
                  {{ item[header.value] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="d-flex justify-end mt-4">
          <v-btn
            elevation="0"
            color="#EDEDED"
            class="mr-2"
            @click="unset"
          >
            Сбросить
          </v-btn>
          <v-btn
            color="#5CB7B1"
            elevation="0"
            dark
            @click="submit"
          >
            Применить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>


import formatDate from '@/mixins/format-date.js'
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from "vuex";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";


export default {
  mixins: [modalWindowMixin, formatDate],
  data: () => ({
    items: [],
    headers: [
      {
        text: "Текущий №",
        value: "sort_index"
      },
      {
        text: "Код товара",
        value: "tn_ved"
      },
      {
        text: "Наименование товара",
        value: "description"
      },
      {
        text: "Происх.",
        value: "origin_country_letter"
      },
    ],
  }),
  computed:{
    ...mapGetters({
      wares:'zvt/getSelectedWares'
    })
  },
  watch:{
    wares:{
      handler(){
        this.setItems()
      },
      immediate:true,
      deep:true,
    }
  },
  methods:{
    setItems(){
      const wares = cloneDeep(this.wares)
      this.items = wares.map(item => {
        const {shipment_date: shipment_date_raw} = item
        const shipment_date = moment(shipment_date_raw).isValid() ? moment(shipment_date_raw).format('DD.MM.YYYY'):null
        return {
          ...item,
          shipment_date,
          new_sort_index:item.sort_index
        }
      })
    },
    submit(){
      const id = this.$route.params.id
      const items = this.items.map(i => i.id)
      this.$store.dispatch('zvt/changeSortIndexes', {
        id,
        items
      }).then(() => {
        this.$store.dispatch('zvt/getDocumentById', id)
        this.close()
        this.$snackbar({
          text:'Готово',
          color:'green',
          top:false
        })
      }).catch(() => {
        this.$snackbar({
          text:'Ошибка',
          color:'red',
          top:false
        })
      })
    },
    unset(){
      this.setItems()
    },
    changeSorting(newSortingIndex, index, item){
      if(Number.isNaN(Number.parseInt(newSortingIndex)) || newSortingIndex < 0 || newSortingIndex > 999 ){
        this.$snackbar({
          text:'Индекс должен быть числом 0<>999',
          top:false,
          color:'red',
        })
        setTimeout(() => {
          this.items.splice(index, 1, item)
        })
      }
      const newIndex = newSortingIndex - 1
      if (newIndex < index) {
        this.items.splice(newIndex,0, item);
        this.items.splice(index + 1, 1);
      } else {
        this.items.splice(index , 1);
        this.items.splice(newIndex, 0, item);
      }
      setTimeout(() => {
        this.items = this.items.map((item, index) => {
          return {
            ...item,
            new_sort_index: index + 1
          }
        })
      })

    }
  }
}
</script>
